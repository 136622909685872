import type * as React from 'react';

export type MapMarkerColor = {
  active: string;
  inactive: string;
};

export const MAP_MARKER_COLOR: Record<string, MapMarkerColor> = {
  POINTS_EXPLORER: { active: '#601b45', inactive: '#84235e' } as const,
  DEFAULT: { active: 'rgb(0 22 59)', inactive: 'rgb(0 47 97)' } as const,
} as const;

export type IMapMarker = {
  /**
   * if `iconActive` is provided, this will switch to use that icon
   */
  isActive?: boolean;
  id: string | number;
  icon?: string;
  label?: string;
  children: React.ReactNode;
  color?: MapMarkerColor;
  /**
   * This event is fired when the marker is clicked.
   */
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  markerOptions?: google.maps.MarkerOptions;
  position: google.maps.MarkerOptions['position'];
};

const MapMarker = ({ isActive, children }: IMapMarker) => (isActive ? children : null);

export default MapMarker;
