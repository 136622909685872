import type { AmenityItem } from '../filters/suggested-filters';
import { SuggestedFilterButtons } from '../filters/suggested-filters';
import { HotelCounter } from '../hotel-counter';
import { MapListToggleButton } from '../map-list-toggle-button/map-list-toggle-button';
import { useConfigRule } from '@dx-ui/framework-config-rule-provider';
import type { ActiveFiltersState } from '../filters/filter.constants';
import type { Maybe } from '@dx-ui/gql-types';
import { useAppState } from '../../providers/app-provider';
import { SMBProfileSwitcher } from '@dx-ui/osc-smb-profile-switcher';
import { useSmbToggle, useWrappedRouter } from '../../hooks';
import { LogInBanner } from '../list-view/log-in-banner/log-in-banner';
import { LocationsPageHeadingLevel1 } from '../locations-page/locations-page-heading';

export const HotelSearchResultSummaryPanel = ({
  amenities,
  customCounterMessage,
  customCounterSRMessage,
  hotelCount,
  isFindingHotels,
  isConductricsLoaded,
  matchId,
  onFilterChange,
  saleFilter,
  visibleHotels,
  pageHeading1Title,
  showingHotelDivRef,
}: {
  amenities?: AmenityItem[];
  customCounterMessage?: string;
  customCounterSRMessage?: string;
  hotelCount: number;
  isFindingHotels: boolean;
  isConductricsLoaded: boolean;
  matchId?: Maybe<string>;
  onFilterChange?: (activeFiltersState: ActiveFiltersState) => void;
  saleFilter?: { showSaleFilter: boolean; saleFilterCount: number };
  visibleHotels: string[];
  pageHeading1Title: string;
  showingHotelDivRef?: React.Ref<HTMLDivElement>;
}) => {
  const { taggedConfig } = useConfigRule();
  const configShowMap = taggedConfig?.config['showMap'] as boolean;
  const { isListVisible, paginationIndex } = useAppState();
  const { safeQueryParams: queryParameters, router } = useWrappedRouter();

  const { handleSMBSwitch, isSMBContextSwitchingEnabled } = useSmbToggle();
  const smbChecked = queryParameters?.smbRate || false;

  return (
    <>
      <div className="flex items-start">
        {router.asPath.includes('/locations/') ? (
          <LocationsPageHeadingLevel1 title={pageHeading1Title} />
        ) : (
          <h1 className="mb-4 font-sans text-xl font-bold">{pageHeading1Title}</h1>
        )}
      </div>
      {isSMBContextSwitchingEnabled ? (
        <SMBProfileSwitcher
          className="mb-4"
          onInputChange={handleSMBSwitch}
          isSmbChecked={smbChecked}
        />
      ) : null}
      <SuggestedFilterButtons
        amenities={amenities}
        isConductricsLoaded={isConductricsLoaded}
        isFindingHotels={isFindingHotels}
        onFilterCriteriaChange={(activeFiltersState: ActiveFiltersState) => {
          if (onFilterChange) onFilterChange(activeFiltersState);
        }}
        visibleHotels={visibleHotels}
        matchId={matchId}
        saleFilter={saleFilter}
      />
      <LogInBanner />
      <div className="flex items-center justify-between pb-2">
        <div ref={showingHotelDivRef} tabIndex={-1}>
          <HotelCounter
            count={hotelCount}
            customMessage={customCounterMessage}
            customSRMessage={customCounterSRMessage}
            currentPageIndex={paginationIndex}
            isListVisible={isListVisible}
            isLoading={isFindingHotels}
          />
        </div>
        <MapListToggleButton showMapListToggleButton={configShowMap} />
      </div>
    </>
  );
};
