import type * as React from 'react';
import Image from 'next/legacy/image';
import { Link } from '@dx-ui/osc-link';
import { useMediaQuery } from 'usehooks-ts';
import cx from 'classnames';

export type InfoWindowProps = {
  /**Full address of the property */
  address?: string | null;
  /**Brand code to render brand logo bug */
  brandCode?: string;
  /** pill badges that flag exceptional attributes of the property such as
   * if a property is new or having a sale
   */
  hotelBadges?: React.ReactNode;
  /** Add additional Custom tailwind styling for info-window container */
  containerClassName?: string;
  /** Property ctyhocn */
  ctyhocn: string | null;
  /**Directions object -
   * title - sting that will display as link text, suggest to pass returned t function string value.
   * uri - link URI
   */
  directionsLink?: {
    linkCopy: string;
    url: string;
  };
  /** hotel name to be used as info-window title and logo bug alt*/
  name?: string | null;
} & React.ComponentProps<'div'>;

export const InfoWindow = ({
  address,
  brandCode,
  children,
  containerClassName,
  ctyhocn,
  directionsLink,
  hotelBadges,
  name,
}: InfoWindowProps) => {
  const isMobile = useMediaQuery('(max-width: 640px)');

  return (
    <div className={cx('max-w-sm bg-[#ffffff] text-[#121212] shadow-sm', containerClassName)}>
      <div
        className="flex max-w-xs rtl:flex-row-reverse"
        data-testid={`${ctyhocn}-mapMarkerContent`}
      >
        {brandCode ? (
          <div className="order-first flex size-8 items-center text-center sm:size-12 rtl:order-last">
            <Image
              alt={`${name || ctyhocn} logo`}
              className="object-contain"
              src={`/modules/assets/svgs/logos/bug/${brandCode}.svg`}
              height={isMobile ? 32 : 48}
              width={isMobile ? 32 : 48}
            />
          </div>
        ) : null}
        <div className="flex flex-col items-start text-left">
          {name ? <div className="w-full px-2 py-1 text-sm font-bold">{name}</div> : null}
          {hotelBadges ? <div className="px-2 ">{hotelBadges}</div> : null}
          {address ? <div className="w-full px-2 py-1 text-xs">{address}</div> : null}
          {directionsLink?.url && directionsLink?.linkCopy ? (
            <Link url={directionsLink.url} isNewWindow={true} className="px-2 text-xs sm:text-sm">
              {directionsLink.linkCopy}
            </Link>
          ) : null}
          {children}
        </div>
      </div>
    </div>
  );
};

export default InfoWindow;
