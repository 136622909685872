'use client';
import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { useTranslation } from 'next-i18next';
import { useIsClient, useMediaQuery } from 'usehooks-ts';

export const MapListToggleButton = ({
  showMapListToggleButton = true,
}: {
  showMapListToggleButton?: boolean;
}) => {
  const { isListVisible } = useAppState();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('search-page');
  const isClient = useIsClient();
  const isSlimView = useMediaQuery('(max-width: 767px)');

  //toggle off MW map view if viewport resized to desktop
  useEffect(() => {
    if (!isSlimView && !isListVisible) dispatch({ type: 'SET_IS_LIST_VISIBLE', payload: true });
  }, [dispatch, isListVisible, isSlimView]);

  const mapListToggleButtonText = useCallback(() => {
    return isListVisible ? t('showMap') : t('showList');
  }, [isListVisible, t]);

  return isClient && showMapListToggleButton ? (
    <button
      className="btn btn-primary items-center md:hidden rtl:ml-0 rtl:mr-auto"
      onClick={() => {
        dispatch({ type: 'SET_IS_LIST_VISIBLE', payload: !isListVisible });
      }}
      type="button"
    >
      {mapListToggleButtonText()}
    </button>
  ) : null;
};
